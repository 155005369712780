<template>
  <div id="app" class="app-body">
    <div class="spacer"></div>
    <div class="columns">
      <div class="column is-6">
        <p class="is-subtitle is-secondary is-bold">Customized Service</p>
        <div v-if="!formSubmitted">
          <p class="mt-5">Are you looking for other brand-building services?</p>
          <p class="mt-5">
            Then you’re in the right place, since the Brandeeq team is also
            helping established brands to become more recognizable. That’s
            either by creating new and additional brand assets, or managing
            brand awareness campaigns.
          </p>
          <p class="mt-5">
            We enable our customers to build unique and distinct brands. Yet,
            the brand-building process stretches far beyond these foundations,
            as a brand grows and matures.
          </p>
          <p class="mt-5">
            Brandeeq’s experts have worked on brand strategy, brand campaign
            planning & management, brand identities and designs.
          </p>
          <p class="mt-5">
            If you’re interested in Brandeeq’s customized, brand-building
            services, please tell us about your organisation and any
            brand-related project that you are considering. We can then arrange
            a call to learn more and then provide a custom proposal.
          </p>
        </div>

        <div v-if="!formSubmitted" class="mt-5">
          <form
            v-if="!formSubmitted"
            @submit.prevent="submitCustomizedService"
            id="form"
          >
            <div class="my-5">
              <b-input
                v-model="responses.q1"
                type="textarea"
                required
              ></b-input>
            </div>

            <p class="mt-5">
              {{ questions.q2 }}
            </p>
            <div class="my-5">
              <b-input
                v-model="responses.q2"
                type="textarea"
                required
              ></b-input>
            </div>

            <p class="mt-5">
              {{ questions.q3 }}
            </p>
            <div class="my-5">
              <b-input
                v-model="responses.q3"
                type="textarea"
                required
              ></b-input>
            </div>

            <p class="mt-5">
              {{ questions.q4 }}
            </p>
            <div class="my-5">
              <b-input
                v-model="responses.q4"
                type="textarea"
                required
              ></b-input>
            </div>

            <p class="mt-5">
              {{ questions.q5 }}
            </p>
            <div class="my-5">
              <b-input
                v-model="responses.q5"
                type="textarea"
                required
              ></b-input>
            </div>

            <div class="my-5 pt-5">
              <button
                form="form"
                class="button is-info"
                type="submit"
                :disabled="isSubmitting"
              >
                <i class="mdi mdi-check mr-1"></i>
                {{ isSubmitting ? "Submitting Response" : "Submit Response" }}
              </button>
            </div>
          </form>
        </div>
        <div class="mt-5" v-if="formSubmitted">
          <p class="mt-5">Thanks for the information.</p>
          <p class="mt-5">
            Our Brandeeq expert will reach out to you within 24 Hours.
          </p>
        </div>
      </div>
      <div class="column">
        <img src="@/assets/img/service_illustrations/custom_illustration.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomizedService",
  data() {
    return {
      fullName: "",
      email: "",
      contact: "",
      formSubmitted: false,
      autofill: false,
      isSubmitting: false,
      questions: {
        q1: "Tell us what you need?",
        q2: "What kind of brand services you are looking for?",
        q3: "What kind(s) of product(s) and/ or service(s) does your organisation offer?",
        q4: "What are some of the goals your brand has?",
        q5: "What budget do you have in mind?",
      },
      responses: {
        q1: "",
        q2: "",
        q3: "",
        q4: "",
        q5: "",
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  created() {
    if (this.currentUser) {
      this.email = this.currentUser.email;
      this.fullName = this.currentUser.fullName;
      this.autofill = true;
      this.contact = this.currentUser.contact;
    }
  },
  methods: {
    async submitCustomizedService() {
      this.isSubmitting = true;
      const submitted = await this.$store.dispatch("submitCustomizedService", {
        fullName: this.fullName,
        email: this.email,
        contact: this.contact,
        questions: this.questions,
        responses: this.responses,
      });
      if (submitted) {
        this.formSubmitted = true;
      }
      this.isSubmitting = false;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
